@import "../style/index.scss";

$menu-width: 180px;

.support-menu {
  //margin: calc(var(--default-spacing-small) * -1);
  overflow: hidden;
  border-radius: var(--default-border-radius);
  min-width: $menu-width;
  background-color: var(--menu-background-color);
  a {
    @include body2;
    display: block;
    padding: var(--default-spacing);
    color: var(--menu-item-color);

    &:hover {
      background-color: var(--menu-item-active-background);
    }
  }
  &__divider {
    border-top: 1px solid var(--menu-item-active-background);
  }
}
