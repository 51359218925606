@import "../style/index.scss";

.tekab-form {
  &--children-as-blocks {
    > * {
      display: block;
      margin-bottom: var(--large-spacing);
    }
  }
}
