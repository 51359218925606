@import "../style/index.scss";

$icon-size: 20px;

.build-page {
  &__header {
    margin-bottom: var(--double-spacing);
    color: var(--black80);

    @include flexFullRowWithSpacing;
    align-items: center;

    .truncated-id {
      @include semiBold;
      color: var(--black100);
    }

    .clickable-id {
      color: var(--black80);
      a {
        color: var(--black100);
      }
    }
  }
  &__build-details {
    display: flex;
    direction: row;

    .log-container {
      margin-left: var(--double-spacing);
    }
  }

  .build-steps__step {
    margin-left: 0;
    margin-right: 0;
    i {
      font-size: $icon-size;
    }
  }
}
