@import "../style/index.scss";

.build-status-icons {
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: var(--large-spacing);
    .tekab-icon {
      color: var(--black80);
      margin-left: var(--default-spacing);
    }
  }
}
