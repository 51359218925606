@import "../style/index.scss";

$message-max-width: 400px;
.related-field-dialog {
  @include modalForm;

  &__instructions {
    max-width: $message-max-width;
    width: 100%;
    text-align: left;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: var(--large-spacing);

    .tekab-button {
      margin-left: var(--large-spacing);
    }
  }
}
