@import "../style/index.scss";

.color-select-button {
  .tekab-button {
    width: var(--default-button-height);
    height: var(--default-button-height);
    border-radius: 6px;
    background-color: red;

    &:hover {
      background-color: initial;
    }
  }
  margin-right: var(--default-spacing);
  &:first-child {
    margin-left: 0;
  }
}
