@import "../style/index.scss";

@mixin active-link {
  background-color: var(--black10);
  color: var(--black100);
}

.inner-tab-link {
  @include body2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--default-spacing-small);
  padding: var(--default-spacing-small);
  border-radius: var(--default-border-radius);
  .tekab-icon {
    margin-right: var(--default-spacing);
  }

  &:hover {
    @include active-link;
  }

  &.active {
    @include active-link;
  }
}
