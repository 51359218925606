@import "../style/index.scss";

$image-height: 130px;

.new-api-token {
  @include modalForm;
  svg {
    height: $image-height;
  }
}
