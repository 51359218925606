@import "../style/index.scss";

.pending-changes-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  h2 {
    @include title1;
    margin: var(--default-spacing);
  }

  .last-commit {
    border-top: $border-black10;
    margin: 0;
  }

  .build-header {
    // margin-top: var(--double-spacing);
    margin-bottom: var(--default-spacing);
    padding-top: var(--double-spacing);
    // border-top: $border-black10;
  }
}
