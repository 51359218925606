@import "../style/index.scss";

.application-form {
  h1 {
    margin-top: var(--double-spacing);
    margin-bottom: var(--double-spacing);
  }
  padding: var(--double-spacing);

  hr {
    border: none;
    border-top: $border-black10;
    margin-top: var(--double-spacing);
    margin-bottom: var(--double-spacing);
  }
  .amplication-menuMode{
    font-size: 10px;
    color: var(--black60);
  }
  h3 {
    display: flex;
    align-items: center;

    i {
      margin-right: var(--icon-spacing);
    }
  }
  .custom-color__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:20px;
    @media (max-width:1200px){
      grid-template-columns: 1fr;
    }

  }
}
