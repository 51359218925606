@import "../style/index.scss";

.new-version-tile {
  @include homePageTile;
  margin: 0;
  border: 2px solid var(--primary);

  &__gap {
    height: 1px;
    background: transparent;
    border: none;
    margin-bottom: var(--double-spacing);
  }
  &__wrapper {
    position: relative;
  }
  &__new-release {
    box-sizing: border-box;
    background-color: var(--primary);
    position: absolute;
    top: calc(var(--triple-spacing) * -1);
    left: 0;
    padding: var(--double-spacing);
    padding-top: var(--default-spacing);
    border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
    color: var(--static-white);
  }
  &__content__details__summary {
    color: var(--negative);
    .tekab-icon {
      color: var(--negative);
    }
  }
}
