@import "../style/index.scss";

.entity-field-list-item {
  margin: 0;
  margin-bottom: var(--default-spacing);

  &__row {
    @include flexFullRowWithSpacing;
    margin-bottom: var(--default-spacing);

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    @include body1;
    color: var(--black100);
  }
  &__description {
    @include body2;
    color: var(--black80);
  }
  &__property {
    color: var(--positive-default);
    border: 1px solid var(--positive-default);
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--default-spacing);
    background-color: rgba(49, 197, 135, 0.09);
    .tekab-icon {
      margin-right: var(--default-spacing);
    }
  }

  &__highlight {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--secondary);

    .tekab-icon {
      margin-right: var(--default-spacing);
    }
  }
}
