@import "../style/index.scss";

.role-list {
  .search-field {
    margin-left: 0;
    margin-bottom: var(--default-spacing);
  }
  &__header {
    margin-bottom: var(--default-spacing);
  }
}
