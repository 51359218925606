@import "../style/index.scss";

.pending-change-diff {
  background-color: var(--black2);
  &__toolbar {
    @include flexFullRowWithSpacing;
    justify-content: flex-end;
    // background-color: var(--secondary-turquoise);
    padding: var(--default-spacing);
    margin: 0;
    .multi-state-toggle {
      width: 150px;
      margin-bottom: 0;
      label {
        display: none;
      }
    }
  }
}
