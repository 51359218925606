/* YourComponent.scss */

/* Base styles for the component container */
.your-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust as needed */
  background-color: #f0f0f0; /* Adjust background color */
}

/* Background image styles */
/* Content container styles */
.content {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9); /* Adjust background color and opacity */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Heading styles */
.content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333; /* Adjust heading text color */
}

/* Paragraph styles */
.content p {
  font-size: 16px;
  color: #666; /* Adjust paragraph text color */
  margin-bottom: 20px;
}

/* Download button styles */
.download-button {
  background-color: #007bff; /* Adjust button background color */
  color: #fff; /* Adjust button text color */
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  /* Add more styles (e.g., hover effects) as needed */
}

.download-button:hover {
  background-color: #0056b3; /* Adjust button background color on hover */
}
