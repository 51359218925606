@import "../style/index.scss";

.entity-list-item {
  margin: 0;
  margin-bottom: var(--default-spacing);

  &__divider {
    @include flexFullRowWithSpacing;
    border-top: $border-black10;
    margin-bottom: var(--default-spacing);
    margin-top: var(--default-spacing);
  }

  &__row {
    @include flexFullRowWithSpacing;
    margin-bottom: var(--default-spacing);

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    @include body1;
    color: var(--black100);
  }
  &__description {
    @include body2;
    color: var(--black80);
  }
}
