@import "../style/index.scss";

$side-min-width: 500px;
$tabs-width: 300px;
.tekab-page-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0 var(--large-spacing);
  background: var(--page-content-background);
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;

  &__tabs {
    @include scrollbars(10px, var(--black60), transparent);
    width: $tabs-width;
    background: var(--page-content-tab-background);
    border-radius: var(--default-border-radius);
    border: $border-black10;
    padding: var(--large-spacing);
    box-sizing: border-box;
    overflow: auto;
    margin: var(--large-spacing) 0;

    .sub-list {
      margin-left: var(--triple-spacing);
    }
  }

  &__main {
    @include scrollbars(10px, var(--black60), transparent);
    flex: 1;
    overflow: auto;
    height: 100%;
    flex: 1;
    padding: var(--large-spacing);
    position: relative;
    box-sizing: border-box;
    height: 100%;

    @media only screen and (max-width: $page-content-min-width) {
      min-width: calc(#{$page-content-min-width} - var(--menu-width));
    }
  }
  .log-container {
    flex: 1;
    width: 50%;
    min-width: $side-min-width;
  }
}
