@import "../style/index.scss";

.github-repo-details {
  width: 100%;
  &__body {
    @include flexFullRowWithSpacing;
  }

  &__name {
    @include title1;
    padding-bottom: var(--default-spacing-small);
  }

  &__status {
    margin-top: var(--default-spacing);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .tekab-icon {
      margin-right: var(--default-spacing-small);
      color: var(--positive-default);
    }
  }
}
