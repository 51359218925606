@import "../style/index.scss";

.build-header {
  @include flexFullRowWithSpacing;
  @include body2;
  @include semiBold;

  width: 100%;

  &--deployed {
    h3,
    h3 span {
      @include semiBold;
      color: var(--positive-default);
    }
    i {
      font-size: 24px;
      color: var(--positive-default);
    }
    a i {
      color: var(--black40);
    }
  }
  .error-message {
    color: rgb(255, 72, 72);
  }
  .error-message:hover {
    text-decoration: underline;
  }
}
