@import "../style/index.scss";

.new-role {
  .text-input input {
    border-style: dashed;
  }

  &__add-field {
    position: relative;
    width: 100%;

    &__button {
      position: absolute !important;
      top: 0;
      right: 0;
      display: none !important;

      &--show {
        display: flex !important;
      }
    }
  }
}
