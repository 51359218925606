@import "../style/index.scss";

$app-icon-spacing: 64px;

.application-layout {
  &__app-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
  &__app-icon {
    &.tekab-menu-item--active:before {
      display: none;
    }
  }
}
