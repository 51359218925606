@import "../style/index.scss";

$button-height: 40px;
$icon-font-size: 24px;

.github-login-button {
  .tekab-button {
    height: $button-height;
    i {
      font-size: $icon-font-size;
      margin-right: var(--default-spacing);
    }
  }
}
