@import "../style/index.scss";

.pending-change-with-compare {
  .pending-change {
    margin-bottom: 0;
  }

  .tekab-panel-collapsible__body {
    padding: 0;
  }
}
