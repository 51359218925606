.tekab-theme-light {
  --surface: #f5f6fa;
  --white: #ffffff;

  --black100: #22273c;
  --black90: #2c3249;
  --black80: #686f8c;
  --black60: #959cb4;
  --black40: #c4c7d5;
  --black30: #d5d8e3;
  --black20: #dfe2ea;
  --black10: #e8eaf0;
  --black5: #f5f6fa;
  --black2: #fafafc;

  --welcome-page-stripe-background: var(--black100);
  --welcome-page-stripe-color: var(--white);

  --panel-background: var(--white);
  --panel-hover-background: var(--black5);

  --menu-background-color: #3F4254;
  --menu-fixed-panel-background: var(--white);

  --page-content-background: var(--white);
  --page-content-tab-background: var(--black5);

  --action-log-background-color: var(--black100);
  --action-log-header-color: var(--white);
  --action-log-step-color: var(--white);

  --image-roles: url("../assets/images/mono/roles.svg");
  --image-entities: url("../assets/images/mono/entities.svg");
  --image-sync-github: url("../assets/images/mono/sync-github.svg");
  --image-commit: url("../assets/images/mono/commit.svg");
  --image-no-changes: url("../assets/images/mono/no-changes.svg");
  --image-add-app: url("../assets/images/mono/add-app.svg");
  --image-relations: url("../assets/images/mono/relations.svg");
  --image-import-excel: url("../assets/images/mono/import-excel.svg");
  --image-my-apps: url("../assets/images/mono/my-apps.svg");
  --image-sample-app: url("../assets/images/mono/sample-app.svg");
  --image-generating: url("../assets/images/mono/generating.svg");
  --image-hono-app: url("../assets/images/hono.svg");
  --image-download-serverless: url("../assets/images/download-serverless.svg");

  --diffViewerBackground: var(--white);
  --diffViewerColor: var(--black100);
  --addedBackground: #e2faf0;
  --addedColor: var(--black100);
  --removedBackground: #ffe0e4;
  --removedColor: var(--black100);
  --wordAddedBackground: #9ae7c7;
  --wordRemovedBackground: #ff9ca9;
  --addedGutterBackground: #caf2e1;
  --removedGutterBackground: #f8ced3;
  --gutterBackground: var(--white);
  --gutterBackgroundDark: var(--white);
  --highlightBackground: var(--white);
  --highlightGutterBackground: var(--white);
  --codeFoldGutterBackground: var(--black10);
  --codeFoldBackground: var(--black5);
  --emptyLineBackground: #161b22;
  --gutterColor: var(--black100);
  --addedGutterColor: var(--black100);
  --removedGutterColor: var(--black100);
  --codeFoldContentColor: var(--black100);
  --diffViewerTitleBackground: var(--white);
  --diffViewerTitleColor: var(--black100);
  --diffViewerTitleBorderColor: var(--white);
}
