@import "../style/index.scss";

.commit-list {
  &__header {
    @include flexFullRowWithSpacing;
    margin-bottom: var(--large-spacing);

    .search-field {
      flex: 1;
    }
  }
  &__title {
    @include body2;
    margin-bottom: var(--large-spacing);
  }
  &__add-button {
    min-width: 0;
  }
}
