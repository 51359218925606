@import "../style/index.scss";

.commit {
  padding-top: 0;
  margin-bottom: var(--default-spacing);
  .tekab-button {
    margin-top: 0;
    margin-top: -10px;
    width: 100%;
  }
  .text-input {
    label {
      margin-bottom: 0;
    }
    textarea {
      height: var(--textarea-height-small);
    }
  }
}
