@import "../../style/index.scss";

$max-width: 700px;
$icon-font-size: 40px;

.sync-with-github-page {
  &__header {
    display: flex;
    align-items: center;
    align-items: flex-start;

    .tekab-icon {
      margin-right: var(--default-spacing-small);
      font-size: $icon-font-size;
    }
  }

  h1 {
    margin-bottom: var(--default-spacing);
  }
  &__message {
    @include body1;
    line-height: 1.5;
    color: var(--black80);
    margin-bottom: var(--triple-spacing);
    max-width: $max-width;
  }
}
