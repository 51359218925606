@import "../style/index.scss";
$empty-state-image-size: 120px;

.application-list {
  &__header {
    @include flexFullRowWithSpacing;
    margin-bottom: var(--large-spacing);

    .search-field {
      width: var(--form-field-long);
    }
  }
  &__title {
    @include body2;
    margin-bottom: var(--large-spacing);
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    img {
      height: $empty-state-image-size;
    }

    &__title {
      @include pageTitle;
      color: var(--black60);
      text-align: center;
      margin-bottom: var(--large-spacing);
    }
  }
}
