@import "../style/index.scss";

.application-list-item {
  margin: 0;
  margin-bottom: var(--default-spacing);

  &__row {
    @include flexFullRowWithSpacing;
    margin-bottom: var(--default-spacing);

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    @include body1;
    color: var(--black100);
  }
  &__description {
    @include body2;
    color: var(--black80);
  }

  &__recently-used {
    margin-left: var(--default-spacing-small);
    height: var(--medium-button-height);
    border-radius: var(--medium-button-radius);
    @include flexFullRow;
    color: var(--black60);
    box-sizing: border-box;
    white-space: nowrap;

    i {
      padding-right: calc(var(--default-spacing-small) / 2);
    }
  }
}
