@import "../style/index.scss";

.last-commit {
  @include flexFullRowWithSpacing;
  height: var(--app-footer-height);
  white-space: nowrap;
  padding: 0 var(--default-spacing);

  .clickable-id {
    color: var(--black80);
    a {
      color: var(--black100);
    }
  }
  .user-and-time {
    margin-left: var(--default-spacing-small);
  }

  .build-summary {
    margin-left: auto;
  }

  .build-header {
    margin-left: var(--double-spacing);
  }

  &__message {
    color: var(--black60);
  }
}
