@import "../../style/index.scss";

.auth-app-with-github {
  margin-left: 0 !important;

  .toggle-field {
    margin: 0;
  }
  &__action {
    margin-left: var(--default-spacing-small);
    margin-right: var(--default-spacing-small);
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
  &__auth {
    margin: var(--double-spacing) 0;
  }
  .tekab-panel-collapsible__header:hover {
    box-shadow: none;
    cursor: default;
  }
  &__select-repo {
    @include flexFullRowWithSpacing;

    &__details {
      @include flexFullRowWithSpacing;
      .tekab-icon {
        margin-right: var(--default-spacing-small);
        color: var(--negative);
      }
    }

    &__name {
      @include title1;
      padding-bottom: var(--default-spacing-small);
    }
  }
  &__notice {
    text-align: left;
    margin-top: var(--default-spacing);
    color: var(--black100);
    margin-bottom: var(--double-spacing);

    ul {
      margin: 0;
      padding: 0;
      color: var(--black60);

      li {
        list-style: none;
        margin: 0;
        margin-top: var(--default-spacing);
        margin-left: var(--default-spacing);
        @include flexFullRow;
        justify-content: flex-start;
        align-items: center;
        .tekab-icon {
          margin-right: var(--default-spacing-small);
          color: var(--positive-default);
        }
      }
    }

    a {
      color: var(--primary);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
