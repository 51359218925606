@import "./style/css-variables.css";
@import "./style/light-css-variables.css";
@import "./style/dark-css-variables.css";
@import "./style/css-variables.css";
@import "./style/index.scss";

body {
  color: var(--black100);
}

#root {
  height: 100vh;
  overflow-y: hidden;
}

.text-medium {
  font-weight: 500;
}

//disable outline on react-hotkeys wrapper
.hotkeys-wrapper {
  outline: 0;
  width: 100%;
  height: 100%;
}

div[tabindex="-1"]:focus {
  outline: 0;
}

.tekab-page main {
  min-width: 500;
}

@media only screen and (max-width: 900px) {
  .tekab-page main {
    min-width: calc(900px - var(--menu-width));
  }
}

::-webkit-scrollbar {
  width: var(--default-spacing);
}
::-webkit-scrollbar-button {
  background-color: var(--black10);
}
::-webkit-scrollbar-track {
  background-color: var(--black5);
}

::-webkit-scrollbar-thumb {
  background-color: var(--black40);
}
